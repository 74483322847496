/* Add this to your Pricecard.css file */
.price-content {
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-out {
    opacity: 0;
    transform: translateY(20px); /* Move downwards slightly when fading out */
  }
  