/* Base Navbar Style */
.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

/* Apply background blur when the user scrolls in light mode */
.navbar.scrolled {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8); /* Light mode background with transparency */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Apply background blur when the user scrolls in dark mode */
html.dark .navbar.scrolled {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.8); /* Dark mode background with transparency */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Darker shadow for dark mode */
}

/* Font styles for list and heading */
ul {
  font-family: "Poppins", sans-serif;
  color: var(--color-text);

}

h4 {
  font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
}

/* Light Mode Styles */
body {
  background-color: #F8F9FA;
  color: #333333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar h4,
.navbar ul li a {
  font-size: 14px;
  color: #333333; /* Dark text for light mode */
}

.mobile-menu ul li a {
  color: #333333; /* Dark text for light mode in mobile menu */
}

.labs {
  color: #1D73B8; /* Accent color for light mode */
}

/* Dark Mode Styles */
html.dark body {
  background-color: #000000;
  color: #f8f9fa;
  transition: background-color 0.3s ease, color 0.3s ease;
}

html.dark .navbar h4,
html.dark .navbar ul li a {
  color: #f8f9fa; /* Light text for dark mode */
}

html.dark .mobile-menu ul li a {
  color: #f8f9fa; /* Light text for dark mode in mobile menu */
}

html.dark .labs {
  color: #00aaff; /* Accent color for dark mode */
}

/* Hamburger Menu Icon */
.hamburger-icon {
  cursor: pointer;
  font-size: 36px;
  color: var(--color-text);
  transition: transform 0.3s ease;
  z-index: 110; /* Increase z-index to ensure it stays above the mobile menu */
  position: relative; /* Ensures the X icon stays on top */
}

.hamburger-icon:hover {
  transform: scale(1.1); /* Add a subtle hover effect */
}

/* Desktop Menu */
ul {
  display: flex;
  gap: 20px;
  list-style: none;
}

ul li a {
  text-decoration: none;
  color: var(--color-text);
  font-size: 16px;
  transition: color 0.3s ease;
}

ul li a:hover {
  color: #1d73b8;
}

/* Mobile Menu Styles */
.mobile-menu {
  position: fixed;
  top: 0;
  left: -100%; /* Start off-screen */
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: left 0.4s ease-in-out; /* Smooth sliding effect */
  z-index: 100; /* Keep the menu behind the hamburger icon */
}

.mobile-menu.open {
  left: 0; /* Slide in */
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu ul li {
  font-size: 24px;
  margin-bottom: 20px;
}

.mobile-menu ul li a {
  color: var(--color-text);
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.mobile-menu ul li a:hover {
  color: #1d73b8;
}

/* Transitions for menu items */
.mobile-menu ul li {
  transition: transform 0.4s ease, opacity 0.4s ease;
  opacity: 0;
  transform: translateY(50px);
}

.mobile-menu.open ul li {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.1s; /* Staggering effect for smoother animation */
}

/* Hide the mobile menu on larger screens */
@media (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}
