body {
  transition: background-color 0.5s ease, color 0.5s ease;
}

/* Wipe overlay animation */
.wipe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
  animation: wipe 1s ease-in-out forwards;
}

/* Wipe effect keyframes */
@keyframes wipe {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
