/* Portfolio Section */
.portfolio-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
  }
  
  /* Text container for the header and paragraph */
  .text-container {
    max-width: 800px;
    text-align: center;
    margin-bottom: 50px;
  }
  
  /* Bento container with grid layout */
  .bento-container {
    display: grid;
    grid-template-columns: 50% 50%; /* Two equal columns */
    grid-template-rows: auto auto; /* Auto heights for rows */
    grid-gap: 4px; /* Reduce grid gap to 1px */
    width: 100%;
  }
  
  /* Full-width item for Project 1 */
  .item-full-width {
    grid-column: span 2; /* Spans both columns */
    height: 219px;
    background-color: #000000; /* Black background */
    color: white;
    /* padding: 20px; */
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2); /* 20% opacity white border */
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Half-width item for Project 2 on the left */
  .item-half-left {
    grid-column: span 1; /* Occupies the first column */
    height: 477px;
    background-color: #000000; /* Black background */
    color: white;
    /* padding: 20px; */
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2); /* 20% opacity white border */
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Stacked items container for Projects 3, 4, and 5 */
  .bento-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Evenly space stacked items */
    height: 477px; /* Matches Project 2's height */
    grid-column: span 1; /* Occupies the second column */
    gap: 1px; /* Reduce the gap between stacked items to 1px */
  }
  
  /* Each stacked item */
  .stacked-item {
    height: 156px;
    background-color: #000000; /* Black background */
    color: white;
    /* padding: 20px; */
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2); /* 20% opacity white border */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Responsive Layout */
  @media (max-width: 768px) {
    .bento-container {
      grid-template-columns: 100%; /* Full-width for all items on smaller screens */
      grid-gap: 1px; /* Ensure 1px gap in mobile view as well */
    }
  
    .item-full-width {
      grid-column: span 1; /* Each item takes full width on smaller screens */
    }
  
    .item-half-left,
    .bento-stack {
      grid-column: span 1; /* Each item takes full width on smaller screens */
    }
  }
  