/* Keyframe for slide in from the left */
@keyframes slideInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Light Mode Styles */
  body {
    background-color: #f8f9fa;
    color: #333333;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Dark Mode Styles */
  body.dark-mode {
    background-color: #000000;
    color: #f8f9fa;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Apply different text colors for light and dark mode */
  .about-section h3,
  .about-section h2,
  .about-section p,
  .about-section span {
    color: #333333; /* Default for light mode */
  }
  
  body.dark-mode .about-section h3,
  body.dark-mode .about-section h2,
  body.dark-mode .about-section p,
  body.dark-mode .about-section span {
    color: #f8f9fa; /* Inverse for dark mode */
  }
  
  /* Animation: Slide in from left */
  .slide-in-left {
    opacity: 1;
    transform: translateX(0);
    animation: slideInLeft 0.6s ease forwards;
  }
  
  /* Initial state: Slide out */
  .slide-out-left {
    opacity: 0;
    transform: translateX(-100px);
    transition: transform 0.6s ease, opacity 0.6s ease;
  }
  
  /* Delays for staggered animations */
  .delay-1 {
    animation-delay: 0.1s;
  }
  
  .delay-2 {
    animation-delay: 0.3s;
  }
  
  .delay-3 {
    animation-delay: 0.5s;
  }
  
  /* Images in the About Section */
  .about-section img {
    opacity: 0;
    transform: scale(0.95); /* Slight scale effect to match text animation */
    transition: opacity 0.6s ease, transform 0.6s ease;
  }
  
  .about-section img.lazyloaded {
    opacity: 1;
    transform: scale(1);
  }
  
  /* Add transitions to images */
  .about-section img {
    opacity: 1 !important;
    transform: scale(1) !important;
  }