@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bevan:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Root Variables - Light Mode */
:root {
  --color-text: #333333;       /* Light mode text color */
  --color-background: #F8F9FA; /* Light mode background */
  --color-primary: #1D73B8;    /* Primary color */
  --color-secondary: #3AAED6;  /* Secondary color */
  --color-accent: #28C4B1;     /* Accent color */
  --button-text: #ffffff;      /* Button text color */
}

/* Dark Mode Variables */
html.dark {
  --color-text: #F8F9FA;       /* Dark mode text color */
  --color-background: #000000; /* Dark mode background */
  --color-primary: #1D73B8;    /* Keep the primary button color consistent */
  --button-text: #F8F9FA;      /* Button text color in dark mode */
}

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body Styling */
body {
  background-color: var(--color-background);  /* Background changes based on light/dark mode */
  color: var(--color-text);                   /* Text color changes based on light/dark mode */
  line-height: 1.6;
  overflow-x: hidden;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition between modes */
}

/* Custom Gradient Text */
.labs {
  background: linear-gradient(90deg, #1D73B8 0%, #2C90C7 50%, #28C4B1 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

/* Typography */
h1, h2 {
  font-family: "Bevan", serif;
  font-weight: 400;
  line-height: 1.15;
  font-size: 4.57rem;
}

h3, h4, h5 {
  font-family: "Poppins", sans-serif;
  color: var(--color-text);  /* Text color adapts to dark mode */
}

p {
  font-family: "Poppins", sans-serif;
  line-height: 33px;
  color: var(--color-text);  /* Text color adapts to dark mode */
}

/* Button Styling */
.idea-to-life-button {
  font-family: "Poppins", sans-serif;
  background-color: var(--color-primary); /* Primary button background */
  color: white;            /* Button text color (light or dark mode) */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.idea-to-life-button:hover {
  background-color: var(--color-secondary); /* Change button color on hover */
}

/* Navbar and Other Component-Specific Styles */
.navbar h4,
.navbar ul li a {
  color: var(--color-text);  /* Navbar text adapts to dark mode */
}

.mobile-menu ul li a {
  color: var(--color-text);  /* Mobile menu text adapts to dark mode */
}

/* Dark Mode Styles for Accent Elements */
html.dark .labs {
  color: #00aaff;  /* Accent color for dark mode */
}
