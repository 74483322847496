/* Modal.css */

/* Fade in/out background overlay */
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .fade-in {
    animation: fadeIn 0.3s forwards;
  }
  
  .fade-out {
    animation: fadeOut 0.3s forwards;
  }
  
  /* Slide up/down for the modal content */
  @keyframes slideUp {
    0% {
      transform: translateY(20%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(20%);
      opacity: 0;
    }
  }
  
  .slide-up {
    animation: slideUp 0.3s forwards;
  }
  
  .slide-down {
    animation: slideDown 0.3s forwards;
  }
  