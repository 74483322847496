/* Keyframe animation for fade-in */
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Light Mode Default Styles */
  body {
    background-color: #F8F9FA;
    color: #333333;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Dark Mode Styles */
  body.dark-mode {
    background-color: #000000;
    color: #f8f9fa;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Hero Section */
  h1 {
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards 0.5s;
  }
  
  .sub-header {
    font-weight: normal;
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards 0.7s;
  }
  
  .devstacks-description {
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards 0.9s;
  }
  
  /* Button Styling */
  .idea-to-life-button {
    background: #1D73B8;
    line-height: 22px;
    letter-spacing: 0;
    font-size: 1rem;
    font-weight: 300;
    white-space: nowrap;
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards 1.0s;
    color: white;
  }
  
  .about-us-button {
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards 1.0s;
  }
  
  /* Image Animation */
  .device {
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards 1.2s;
  }
  
  /* Light Mode Text Colors */
  h1,
  .sub-header,
  .devstacks-description {
    color: #333333; /* Dark text for light mode */
  }
  
  /* Dark Mode Text Colors */
  body.dark-mode h1,
  body.dark-mode .sub-header,
  body.dark-mode .devstacks-description,
  body.dark-mode .idea-to-life-button,
  body.dark-mode .about-us-button {
    color: #f8f9fa; /* Light text for dark mode */
  }
  
  /* Adjust button background for dark mode */
  body.dark-mode .idea-to-life-button {
    background: #00aaff; /* Light blue accent for dark mode */
  }
  
  body.dark-mode .about-us-button {
    background: none;
    border: 2px solid #00aaff; /* Border change for dark mode */
  }

  /* Blur background that follows the mouse */
.blue-blur {
  position: absolute;
  width: 600px;
  height: 600px;
  background: rgba(29, 115, 184, 0.6); /* Blue color */
  filter: blur(350px); /* Default blur effect for Chrome */
  border-radius: 50%; /* Make it circular */
  pointer-events: none; /* Ignore pointer events */
  transform: translate(-50%, -50%); /* Center the blur at the mouse */
  transition: all 0.1s ease-out; /* Smooth movement */
  z-index: -1; /* Place it behind the content */
  opacity: 0.7;
}

/* Specific adjustment for Firefox */
@supports (-moz-appearance: none) {
  .blue-blur {
    width: 200px;
    height: 200px;
    filter: blur(250px); /* Reduce blur effect for Firefox */
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    will-change: transform;
  }
}

/* Ensure the homepage content is relative so the blur stays behind */
.relative {
  position: relative;
}

/* Additional styles for the homepage */
.w-full {
  overflow: hidden; /* Prevents the blur from going out of bounds */
}
